<template>
<div>
  <!-- Select -->
  <div class="d-flex flex-wrap align-content-stretch align-items-top">
    <div class="col-md-4 p-0" v-for="type in eventTypes" :key="type.type">
    <b-card class="m-2"
      :title="type.name"
      :img-src="eventTypesImages[type.type]" img-alt="Image" img-top>
      <b-card-text>
        <b-badge v-if="type.beta">beta</b-badge>
        {{ type.desc }}
      </b-card-text>
      <button class="btn btn-primary mt-2" @click="$router.push(`/guest/events/create/${type.type}`)">Create</button>
    </b-card>
    </div>
  </div>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('events-create');
import { eventTypes } from '@/services/event-manager';

export default {
  data() {
    return {
      eventTypes,
      eventTypesImages: {
        stage: require('@/assets/events/show.jpg'),
        meeting: require('@/assets/events/meeting.jpg'),
        videoQnA: require('@/assets/events/videoQnA.jpg'),
        matchmaking: require('@/assets/events/matchmaking.jpg'),
        webinar: require('@/assets/events/webinar.jpeg'),
        queue: require('@/assets/events/officehours.jpg'),
      }
    };
  },
  async mounted() {
    log.log("init", this.$route.query);
  },
}
</script>

<style lang="scss" scoped>

</style>